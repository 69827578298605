import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseMetricInterface, MetricInterface, MetricParams} from '@ypa/types/metric';
import {
    AbstractEntityWithClubRelationRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {MetricRepository} from "@ypa/state-management/shared/metric";
import {BaseMetricClubInterface, MetricClubInterface, MetricClubParams} from "@ypa/types/metric-club";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class MetricService extends AbstractEntityWithClubRelationRepositoryService<MetricInterface, BaseMetricInterface, MetricClubInterface, BaseMetricClubInterface, MetricParams, MetricClubParams> {

    constructor(
        private http: HttpClient,
        private metricRepository: MetricRepository
    ) {
        super(metricRepository);
    }

    protected getByReq(params: MetricParams): Observable<MetricInterface[]> {
        if (environment.endpointMainPrefix) {
            return this.http.get<MetricInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/metric`, { params: params as {} });
        }else {
            return this.http.get<MetricInterface[]>(`${environment.apiUrl}/metric`, { params: params as {} });
        }
    }

    protected getAllReq(): Observable<MetricInterface[]> {
        if (environment.endpointMainPrefix) {
            return this.http.get<MetricInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/metric`);
        }else{
            return this.http.get<MetricInterface[]>(`${environment.apiUrl}/metric`);
        }
    }

    protected createReq(form: BaseMetricInterface | Partial<MetricInterface>): Observable<MetricInterface> {
        return this.http.post<MetricInterface>(`${environment.apiUrl}/metric`, form);
    }

    protected updateReq(id: number, form: Partial<MetricInterface>): Observable<MetricInterface> {
        return this.http.patch<MetricInterface>(`${environment.apiUrl}/metric/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/metric/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricInterface> {
        return this.http.get<MetricInterface>(`${environment.apiUrl}/metric/${id}`);
    }

    protected updateListReq(list: EntityListInterface<MetricInterface>): Observable<EntityListInterface<MetricInterface>> {
        throw new Error('Method not implemented.');
    }
}
